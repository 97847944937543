















































import { Component, Prop, PropSync } from 'vue-property-decorator'
import set from 'lodash/set'
import uniqBy from 'lodash/uniqBy'

import {
  AirportScheduleItem
} from '../../../../contexts/checkout/contracts/flight-data'
import { FormErrorsMixin } from '../../../../support/mixins'
import { SelectItem } from '../../../../dsl/atoms/Select'

import { CheckoutPayload } from '../../contracts'

import { GuestDetailsFormFieldsetProps as Data } from '../GuestDetailsFormFieldset'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl.
 */
@Component<FlightDataFieldset>({
  name: 'FlightDataFieldset'
})
export default class FlightDataFieldset extends FormErrorsMixin {
  @PropSync('payload', { type: Object, required: true })
  public _payload!: CheckoutPayload

  @PropSync('date', { required: true })
  public _date!: string

  @Prop({ type: Object, required: false, default: null })
  public errors!: Data['_errors']

  @PropSync('flightData', { required: true, default: null })
  public _flightData!: Array<AirportScheduleItem> | null

  @Prop({ type: Boolean, required: true, default: false })
  public disableFields!: boolean

  public onSearch (phrase: string) {
    if (!this._flightData) {
      return
    }

    // Calculate number of whitespaces that should be added at the end of flight number
    const whitespacesAtTheEndOfPhrase: number = phrase.split(' ').length - 1 > 0
      ? phrase.split(' ').length - 1
      : 0

    this._flightData = this._flightData
      .map((flight) => {
        const found = flight.flight.toString().includes(phrase.trimEnd())

        return found ? {
          ...flight,
          flight: flight.flight + ' '.repeat(whitespacesAtTheEndOfPhrase)
        } : flight
      })
  }

  public get scheduledFlights (): Array<SelectItem> {
    return this._flightData
      ? uniqBy(this._flightData.map((option) => ({ label: option.flight, value: option.flight })), 'value')
      : []
  }

  public get flightRoute (): string | undefined {
    if (!this._flightData || !this._payload.flightNumber) {
      return
    }

    const selectedFlight = this._flightData.find((flight) => flight.flight === this._payload.flightNumber)

    if (!selectedFlight) {
      return
    }

    return `${this.$t('front.checkout.molecules.FlightDataFieldset.route.label').toString()} (${selectedFlight.origin.translated}) - (${selectedFlight.destination.translated})`
  }

  public considerDisabledDate (date: Date): boolean {
    const oneDay = 1000 * 60 * 60 * 24
    const nextHalfYear = new Date().getTime() + (180 * oneDay)

    return (date.getTime() + oneDay) < new Date().getTime() || (date.getTime() + oneDay) > nextHalfYear
  }

  public onFlightDateUpdate (newDate: string): void {
    this.$emit('updateDate', newDate)
  }

  public onFlightNumberUpdate (newFlightNo: string): void {
    if (!this._flightData) {
      return
    }

    const newFlight = this._flightData
      .filter(data => data.flight.includes(newFlightNo))

    set(this._payload, 'flightNumber', newFlight ? newFlight[0].flight : this._payload.flightNumber)
  }
}
